import * as React from "react"
import PropTypes from "prop-types"
import HeaderNew from "../header/header-new"
import FooterUpdate from "../footer/footer.-update"

const Layout = ({ children, isHome = false }) => {
  return (
    <>
      <HeaderNew isHome={isHome} />
      <div className="mt-14 lg:mt-20">
        <main>{children}</main>
      </div>
      <FooterUpdate />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;