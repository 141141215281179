import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const HeroSectionWithTitle = ({
  title,
  description,
  textLocation = "right",
  image,
  imagePosition = "top",
}) => {
  return (
    <div
      className={`w-full relative flex overflow-hidden h-300 bg-site-gray lg:rounded-bl-100 ${
        textLocation === "left" ? "flex-row-reverse" : ""
      }`}
    >
      {textLocation === "right" ? (
        <>
          <div className="flex w-full lg:w-1/2 h-full relative bg-site-dark-gray">
            <StaticImage
              id="cover"
              src="../../../images/blank.png"
              quality={95}
              placeholder="DOMINANT_COLOR"
              formats={["AUTO", "WEBP"]}
              alt={`Services-${title}`}
              className="w-full h-full z-5 top-0 left-0"
              objectFit="cover"
              style={{position: "absolute"}}
            />
            <GatsbyImage
              image={image}
              alt={`Services`}
              style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
              objectPosition={imagePosition}
            />
            <div className="absolute top-0 -right-1 w-full h-full bg-transparent bg-toTopGray lg:bg-toRightGray">
              &nbsp;
            </div>
          </div>
          <div className="w-full lg:w-1/2 pt-12 lg:pt-4 px-4 flex flex-col h-full justify-center absolute lg:static">
            <div className="w-full flex items-center justify-center pb-4">
              <span
                className="text-white text-center text-4xl tracking-widest"
                style={{ textShadow: "2px 2px 5px #333333" }}
              >
                {title}
              </span>
            </div>
            <div
              className="p-1 text-white lg:text-site-yellow flex flex-col items-center text-sm lg:text-xl gap-y-4 px-2 md:px-4"
              style={{ textShadow: "1px 1px 20px black" }}
            >
              <p>{description}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex w-full lg:w-1/2 h-full relative">
            <GatsbyImage
              image={image}
              alt={`Services`}
              style={{ width: "100%", height: "100%", filter: "brightness(1)" }}
              objectPosition={imagePosition}
            />
            <div className="absolute top-0 -left-1 w-full h-full bg-transparent bg-toTopGray sm:bg-toLeftGray">
              &nbsp;
            </div>
          </div>
          <div className="w-full lg:w-1/2 pt-12 lg:pt-4 px-4 lg:pl-16 flex flex-col h-full justify-center absolute lg:static ">
            <div className="w-full flex items-center justify-center pb-4 ">
              <span
                className="text-white text-center text-4xl tracking-widest"
                style={{ textShadow: "2px 2px 5px #333333" }}
              >
                {title}
              </span>
            </div>
            <div
              className="p-1 text-white lg:text-site-yellow flex flex-col items-center text-sm lg:text-xl gap-y-4 px-2 md:px-4"
              style={{ textShadow: "1px 1px 20px black" }}
            >
              <p>{description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default HeroSectionWithTitle
